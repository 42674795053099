.create-profile .box-with-form {
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  background: var(--background-form);
  border-radius: 8px;
  padding: 10px;
}
.create-profile .price-box-left-right .right-group {
  margin-top: 3px;
  /* width: 60px; */
  width: 150px;
  text-align: center;
}
.create-profile .price-box-left-right .right-group input::placeholder {
  text-align: center;
  font-weight: 800;
  color: var(--secoundry-color);
}
.create-profile .price-box-left-right .left-group {
  width: 100%;
}

/*.fixed-size-image {
  width: 100px;
  height: auto; 
  max-width: 100%;
  max-height: 100%; 
  object-fit: contain;
}
 */