.top-search-sec form input {height: 48px;border-radius: 8px;font-size: 14px;padding-right: 38px;background: #F6F4FC;}
.top-search-sec button {position: absolute;top: 0;right: 0;border: none;height: 48px;color: var(--secoundry-color);}
.top-search-sec form input::placeholder {font-size: 15px;font-weight: 500;line-height: 18px;letter-spacing: -0.01em;color: #9698A5;}

.select-all-sec .checkbox-list-item .form-check {margin: 0 14px 0 0;padding: 0;}
.select-all-sec .checkbox-list-item .form-check .form-check-input {margin-top: 2px;}
.select-all-sec .checkbox-list-item .text {font-size: 14px;}

.admin-menu-sec ul li a {padding: 13px 0;font-size: 16px;font-weight: 400;line-height: 20.08px;letter-spacing: -0.02em;}
.admin-menu-sec {margin-top: 30px;}
.pb-100 {padding-bottom: 100px;}

.primary-color-text {color: var(--primary-color);}
.checkbox-list-item {padding: 13px 0;font-size: 16px;font-weight: 400;line-height: 20.08px;letter-spacing: -0.02em;}
.checkbox-list-item .form-check .form-check-input {margin: 0;}
.checkbox-list-item .form-check {min-height: auto;margin-bottom: 0;}

.select-all-sec .checkbox-list-item .form-check {margin: 0 14px 0 0;padding: 0;}
.select-all-sec .checkbox-list-item .form-check .form-check-input {margin-top: 2px;}
.select-all-sec .checkbox-list-item .text {font-size: 14px;}