@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
  font-size: 16px;
  overflow-x: hidden;
  color: var(--secoundry-color);
  font-family: "Mulish", sans-serif;
  background-color: var(--body-bg-color);
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  --scroll-width: 0px;
}

body,
html {
  width: 100%;
  height: 100%;
}

a {
  outline: none !important;
  color: var(--secoundry-color);
}

/* Global css start*/
.btn-primary,
.forgot-link a,
.link-remember-me .form-check-label,
.btn.btn-link {
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

img {
  max-width: 100%;
}
a,
a:hover {
  text-decoration: none;
}
button,
select {
  outline: none !important;
}
ul,
ol {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

/* :root {
  --primary-color: #805ed1;
  --secoundry-color: #34353c;
  --bs-white: #fff;
  --body-bg-color: #faf5fa;
  --light-gray-color: #9698a5;
  --btn-light-color: #cecece;
  --red-color: #ee2c55;
  --background-form: #f2effa;
  --bs-black: #000;
  --bs-yellow: #ffd941;
  --bs-light-primary: #e3d8ff;
} */

:root {
  /* / --primary-color: #805ed1; / */
  --secoundry-color: #34353c;
  /* / --primary-color: #86b7fe; / */
  --primary-color: #86b7fe;
  /* / --secoundry-color: #86b7fe6b; / */
  --bs-white: #fff;
  --body-bg-color: #f3f8ff;
  --light-gray-color: #9698a5;
  --btn-light-color: #cecece;
  --red-color: #ee2c55;
  /* / --background-form: #f2effa; / */
  --background-form: #eff2fa;
  --bs-black: #000;
  --bs-yellow: #ffd941;
  /* / --bs-light-primary: #e3d8ff; / */
  --bs-light-primary: #e0f0ff;
}

body.dark-mode {
  --primary-color: #b00009;
  --secoundry-color: #fff;
  --bs-white: #fff;
  --bs-dark-color: #44454f;
  --red-color: #b00009;
  --background-form: transparent;
}

.dark-mode .toolbar-center {
  color: var(--bs-white);
}
.dark-mode .back-button,
body.dark-mode .user-setting {
  color: var(--bs-white);
}
.dark-mode .btn-link {
  color: var(--bs-white);
}
.dark-mode .input-single .form-control {
  color: var(--bs-white);
}
.dark-mode .input-single .form-control::-ms-input-placeholder {
  color: #fff;
  opacity: 0.8;
}
.dark-mode .input-single .form-control::placeholder {
  color: #fff;
  opacity: 0.8;
}
.dark-mode select option {
  color: var(--bs-gray-dark);
}
.dark-mode .input-single select.form-control {
  background-image: url(./assets/images/dark-select-dropdown.png);
}
.dark-mode .game-listing {
  background-color: transparent;
}
.dark-mode .footer {
  background-image: url(./assets/images/dark-footer-bg.png);
}
.dark-mode .back-button .btn svg path,
.dark-mode .user-setting .btn svg path,
.dark-mode .user-setting svg path,
.dark-mode .user-setting svg ellipse {
  stroke: var(--bs-white);
}
.top-search-sec button {
  background: initial !important;
}
.dark-mode .top-search-sec button {
  color: var(--bs-dark-color);
}
.dark-mode .not-found-bot a svg path,
.dark-mode .not-found-bot a svg circle {
  stroke: var(--primary-color);
  fill: var(--bs-white);
}
.dark-mode .not-found-bot a svg path,
.dark-mode .not-found-bot a svg {
  fill: var(--primary-color);
}
.dark-mode .btn-cancel {
  color: var(--bs-dark-color);
}
.dark-mode .btn-delete {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.dark-mode .profile-management-details .form-group .edit-icons a {
  color: var(--bs-white);
}
.dark-mode .file-upload label svg circle {
  fill: var(--primary-color);
}
.dark-mode
  .User-details
  .profile-management-details
  .form-group
  .details
  a
  .icons
  i {
  color: var(--bs-white);
}
.dark-mode .common-btn .btn-delete {
  background-color: var(--btn-light-color);
  border-color: var(--btn-light-color);
  color: var(--bs-dark-color);
}
.dark-mode .User-details .profile-management-details .form-group .details a {
  color: var(--bs-white);
}
.dark-mode .primary-color-text {
  color: var(--bs-white);
}
.dark-mode .checkbox-list-item .form-check .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.dark-mode .checkbox-list-item .form-check .form-check-input {
  margin: 0;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid var(--bs-white);
}

.dark-mode .input-single.upload-file {
  background: linear-gradient(112.83deg, #887579 0%, #45131a 112.84%);
}
.dark-mode .input-single.upload-file .input-group span {
  color: var(--bs-white);
}
.dark-mode .input-single.upload-file .input-group span path {
  /* fill: var(--primary-color); */
}
.dark-mode
  .create-profile
  .box-with-form
  .form-group.input-single
  label
  svg
  path {
  fill: var(--bs-white);
}
.dark-mode button.setting-btn i {
  color: var(--bs-white);
}
.dark-mode .user-setting .dropdown ul.dropdown-menu {
  background-color: var(--primary-color);
}
.dark-mode .user-setting .dropdown ul.dropdown-menu li a {
  color: var(--bs-white);
}
.dark-mode .user-setting .dropdown ul.dropdown-menu li svg path {
  fill: var(--primary-color);
  stroke: var(--bs-white);
}
.dark-mode .create-profile .create-draw-time .box-with-form {
  background: #ffe9ea;
}
.dark-mode .gift-draw-box.box-with-form {
  border-color: var(--primary-color);
  background-color: #ffe9ea;
  color: var(--bs-dark-color);
}
.dark-mode .join-group {
  background: #ffe9ea;
  color: var(--bs-dark-color);
}
.dark-mode .join-group .refres svg path {
  stroke: none;
  fill: var(--primary-color);
}
.dark-mode .btn.btn-border-with.select {
  color: var(--bs-white);
  background-image: url(./assets/images/dark-select-dropdown.png);
}
.dark-mode
  .leaderboard-section
  .leaderboard-body
  table
  tr:nth-child(2n + 1)
  td {
  background-color: var(--primary-color);
}
.dark-mode .leaderboard-section .leaderboard-body table {
  border-color: var(--bs-white);
}
.dark-mode
  .leaderboard-section
  .leaderboard-body
  table
  tr
  td
  .invitations-box
  .icons
  img {
  filter: brightness(0) invert(1);
}
.dark-mode .leaderboard-section .leaderboard-body table tr td .invitations-box {
  color: var(--bs-white);
}
.dark-mode .send-modal .modal-footer li svg path {
  stroke: var(--primary-color);
}
.dark-mode .got-to-payment-sec .offcanvas {
  background: var(--bs-black);
  border-color: var(--bs-dark-color);
}
.dark-mode .got-to-payment-sec .offcanvas .payment-box ul li {
  color: var(--bs-white);
}
.dark-mode .form-check-input[type="radio"] {
  border: 1px solid var(--primary-color);
  background: transparent;
}
.dark-mode .form-check-input:checked {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-white) !important;
  background: var(--primary-color)
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: 96% !important;
}
.dark-mode .leaderboard-section .leaderboard-body table tr:last-child td {
  background: transparent;
}
.dark-mode .drawn-list .drawn-box {
  border-color: var(--bs-white);
}
.dark-mode .drawn-list .drawn-box .bottom-drawn-box .border-btn.btn {
  background-color: var(--primary-color);
  color: var(--bs-white);
  border-color: var(--bs-white);
}
.dark-mode .drawn-list .drawn-box .bottom-drawn-box .border-btn.btn svg path {
  fill: var(--bs-white);
}
.dark-mode .congratulations-content .congratulations-box p {
  color: var(--bs-dark-color);
}
.dark-mode .congratulations-content .congratulations-box {
  background-color: #ffe9ea;
}
.dark-mode a:hover {
  text-decoration: none;
  color: var(--primary-color);
}
/* Global css end*/

.bg-image {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100svh;
  z-index: -1;
  display: none;
}
.bg-image.splash-bg {
  display: block;
}
.dark-mode .bg-image {
  display: block;
}
.splash {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.splash {
  opacity: 1;
  visibility: visible;
  animation: fadeIn 1s ease-in-out;
}
.dark-mode .logo img {
  filter: brightness(100);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.header-toolbar {
  min-height: 58px;
  margin-bottom: 30px;
  padding: 10px 0px;
  font-size: 18px;
}
.form-group {
  margin-bottom: 20px;
}
.input-single .form-control {
  height: 48px;
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
}
.input-single label {
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16.32px;
  letter-spacing: -0.01em;
}
.form-control:focus {
  border-color: var(--primary-color);
  outline: 0;
  -webkit-box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

/* .back-button, .user-setting {min-width: 42px;} */
.btn-link {
  color: var(--secoundry-color);
}
/*.btn-link:hover{ color: var(--bs-white);} */
.back-button .btn:hover,
.user-setting .btn:hover {
  background-color: transparent;
}
.back-button .btn,
.user-setting .btn {
  padding: 0;
  font-size: 20px;
}
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 13px;
  font-weight: bold;
  border-radius: 30px;
  padding: 14px 6px;
  text-transform: capitalize;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.sec-login-register .logo {
  margin-bottom: 95px;
}
.link-remember-me .form-check-label {
  cursor: pointer;
}
.link-remember-me .form-check-label a {
  color: var(--primary-color);
}

.link-remember-me .form-check-input {
  /* background-color: transparent; */
  /* border: 1px solid rgb(255 255 255 / 60%); */
  margin-top: 5px;
  box-shadow: none;
}
.form-actions-button {
  margin-top: 55px;
}
/* .forgot-link a:hover{ color: var(--primary-color) !important;} */
/* .link-remember-me .form-check-label:hover{ color: var(--primary-color) !important;} */
.sec-login-register {
  padding-bottom: 50px;
}
/* .sec-sign-in .input-single .form-control {border-radius: 0;border: 0;border-bottom: 1px solid;} */
.sec-sign-in .input-single > .form-control:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}
/* .btn.btn-link:hover {background-color: var(--primary-color);} */
.toolbar-center {
  font-weight: 500;
}
.sec-login-register .form-actions-button p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.input-single select.form-control {
  background-image: url(./assets/images/select-dropdown.png);
  background-repeat: no-repeat;
  background-position: 99%;
  padding-right: 30px;
  background-size: 12px;
}
.input-single.password-container input {
  padding-right: 45px;
}
.input-single.password-container {
  position: relative;
}
.input-single.password-container span {
  position: absolute;
  bottom: 12px;
  right: 15px;
}
/*****************draw page end here*************/

/*************home page lightmode************/
.top-bar ul li {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}
.top-bar {
  padding: 25px 0;
}
.offer-box {
  position: relative;
  width: calc(33.33% - 10px);
  margin: 5px;
  background-color: #805ed1;
  border-radius: 20px;
  overflow: hidden;
}
.middle-offer-bar {
  margin: -5px 0px 25px;
}
.offer-box .text-top-box {
  padding: 15px 8px 0;
  text-align: center;
}
.offer-box .text-top-box h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: var(--bs-white);
  margin: 0;
}
.offer-box .img-bottom-box img {
  float: right;
}
.blue-bg {
  background-color: #72b2fc;
}
.green-bg {
  background-color: #6ee8c3;
}
.offer-box:before {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  border: 14px solid #fff;
  opacity: 0.1;
  border-radius: 50%;
  right: -46px;
  top: -31px;
}
.game-listing {
  background-color: var(--bs-white);
  padding: 15px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: 84px;
}
.game-listing .heading-listing h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin: 0;
}
.game-listing .heading-listing {
  margin-bottom: 12px;
}
.game-listing .gaming-common-list {
  margin: -5px 0px;
}
.game-listing .gaming-common-list .gaming-list {
  width: calc(50% - 10px);
  margin: 5px;
  box-shadow: 2px 4px 14px 0px #1e266d66;
  border-radius: 10px;
  overflow: hidden;
}
.game-listing .gaming-common-list .gaming-list img {
  width: 100%;
  display: block;
}
.footer {
  width: 100%;
  background-image: url(./assets/images/footer-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  bottom: 0;
  background-size: cover;
  height: 120px;
}
.add-upload {
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 75px;
  height: 75px;
  background-color: var(--primary-color);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .footer-box {
  margin-top: 62px;
  width: 30%;
}
.footer a {
  color: var(--bs-white);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}
.footer svg {
  fill: var(--bs-white);
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 21px;
  height: 21px;
}
.footer a span {
  display: block;
  margin-top: 4px;
}
.footer .add-upload svg {
  width: 33px;
  height: 34px;
}
.offer-slider-sec img {
  border-radius: 10px;
}
.offer-slider-sec .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.offer-slider-sec .carousel-indicators {
  margin-bottom: 5px;
}
.offer-slider-sec .carousel-indicators .active {
  background-color: var(--primary-color);
}
.offer-slider-sec {
  margin-bottom: 25px;
}

/*****Created this class because the h3 is not working properly into the Card********/
.gift-draw-box h3 {
  background: var(--primary-color);
  color: var(--bs-white);
  text-align: center;
  padding: 15px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
  margin: -10px -10px 0;
}

.gift-draw-box ul li {
  font-size: 15px;
  font-weight: 700;
  line-height: 18.83px;
  letter-spacing: -0.01em;
  padding: 10px 0;
  margin: 0;
  border-bottom: 1px solid #ced4da;
}
.gift-draw-box ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.gift-draw-box ul li span {
  font-weight: 400;
}

/*****Created this class because the h3 is not working properly into the Card********/
/**Delete Btn**/
.btn-delete {
  background-color: var(--red-color);
  border-color: var(--red-color);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: bold;
  border-radius: 30px;
  padding: 14px 20px;
  text-transform: capitalize;
  color: var(--bs-white);
}
.btn-delete:hover {
  background-color: transparent;
  color: var(--red-color);
}
/* 
Refreshing animation */
.refreshing {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.close-button.btn.btn-primary {
  position: relative;
  top: 20px; /* Adjust as needed */
  left: 92%;
  cursor: pointer;
  z-index: 100;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* Ensure background color is set */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
    /* light shadow */ 0px 8px 15px rgba(0, 0, 0, 0.2) !important; /* dark shadow */
  border: none; /* Remove any default border */
  padding: 0; /* Remove padding */
}

.alreadyJoined-group {
  background: #cbffdb;
  border-radius: 8px;
  padding: 11px 11px;
  color: #000;
}

.alreadyJoined-group p {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
  padding: 0 10px;
}
.alreadyJoined-group p span {
  display: block;
  font-weight: 400;
  font-size: 14px;
}
.alreadyJoined-group .btn.btn-primary {
  padding: 6px 6px;
}

/*********leaderboard page***********/

.leaderboard-section .top-leaderboard {
  background: var(--primary-color);
  padding: 12px;
  text-align: center;
  color: var(--bs-white);
  /* border-top-right-radius: 8px;
  border-top-left-radius: 8px; */
  border-radius: 8px;
}
.leaderboard-section .top-leaderboard h3 {
  font-size: 15px;
  font-weight: 900;
  line-height: 18.83px;
  letter-spacing: -0.01em;
  margin: 0;
}
.leaderboard-section .top-leaderboard h3 span {
  display: block;
  color: var(--bs-yellow);
}
.leaderboard-section .leaderboard-body table {
  table-layout: fixed;
  border: 1px solid var(--primary-color);
  background: var(--background-form);
}
.leaderboard-section .leaderboard-body table th:nth-child(3) {
  text-align: center;
  width: 88px;
}
.leaderboard-section .leaderboard-body table th {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  padding: 12px 8px;
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td {
  padding: 12px 8px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16.32px;
  letter-spacing: -0.01em;
}
.leaderboard-section .leaderboard-body table tr:nth-child(2n + 1) td {
  background: var(--bs-light-primary);
}
.leaderboard-section .leaderboard-body table tr td:last-child {
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td .invitations-box {
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  color: var(--primary-color);
}
.leaderboard-section .leaderboard-body table th:nth-child(1) {
  width: 60px;
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td:nth-child(1) {
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td:nth-child(2) {
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td:nth-child(2) .author-box {
  /* text-align: left;
  max-width: 100px; */
  margin: 0 auto;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 800;
}
.leaderboard-section
  .leaderboard-body
  table
  tr
  td:nth-child(2)
  .author-box
  .author-name {
  padding-left: 5px;
}
.leaderboard-section .leaderboard-body table tr td .invitations-box .icons img {
  width: 14px;
  display: inline-block;
  margin-top: -3px;
  margin-left: 3px;
}
.leaderboard-section .leaderboard-body table {
  border-bottom-left-radius: 8px;
  overflow: hidden;
  border-bottom-right-radius: 8px;
}

/***********drawn-list page*************/

.drawn-list .drawn-box {
  /* border: 1.19px solid #805ed1; */
  border: 1.19px solid var(--primary-color);
  background: var(--background-form);
  border-radius: 8px;
  padding: 11px;
}
.drawn-list .drawn-box .left-box {
  width: calc(100% - 30px);
  padding-right: 10px;
}
.drawn-list .drawn-box .right-box {
  width: 30px;
  text-align: right;
}
.drawn-list .drawn-box .right-box span {
  display: block;
  font-size: 16.7px;
  font-weight: 600;
  line-height: 20.96px;
  letter-spacing: -0.01em;
  margin-bottom: 8px;
}
.drawn-list .drawn-box .right-box span:last-child {
  margin-bottom: 0;
}
.drawn-list .drawn-box .left-box .img-icons {
  width: 50px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;
}
.drawn-list .drawn-box .left-box .img-icons img {
  width: 50px;
  display: block;
  object-fit: cover;
  height: 50px;
}
.drawn-list .drawn-box .left-box .text-box {
  width: calc(100% - 60px);
}
.drawn-list .drawn-box .left-box .text-box p {
  margin: 0;
  font-size: 16.7px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drawn-list .drawn-box .bottom-drawn-box {
  margin-top: 24px;
}
.drawn-list .drawn-box .bottom-drawn-box ul li {
  width: calc(100% - 60px);
  margin-right: 10px;
}
.drawn-list .drawn-box .bottom-drawn-box .border-btn.btn {
  border: 1px solid var(--primary-color);
  width: 100%;
  display: block;
  text-align: center;
  padding: 11px 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: var(--primary-color);
}
.drawn-list .drawn-box .bottom-drawn-box ul li:last-child {
  width: 50px;
  margin-right: 0;
}
.drawn-list .drawn-box .bottom-drawn-box .border-btn.btn svg {
  display: block;
}

/*****************setting**********************/

.profile-top.portfolio-top-setting {
  margin: -15px 0 0;
}
.profile-top.portfolio-top-setting img {
  width: 100px;
  height: 100px;
}
.profile-top.portfolio-top-setting .profile-details {
  margin: 0;
}

.admin-menu-sec ul li a {
  padding: 13px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.02em;
}
.admin-menu-sec {
  margin-top: 30px;
}
.pb-100 {
  padding-bottom: 100px;
}
.profile-top {
  margin-top: -70px;
}
.profile-top .profile-img img {
  width: 125px;
  height: 125px;
  border-radius: 100%;
}
.profile-top .profile-details {
  margin: 20px 0 35px;
}
.profile-top .profile-details h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 38px;
  margin: 0;
}
.profile-top .profile-details p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

/*************bot list********************/

.setting-btn .bi-plus-lg {
  font-size: 20px;
}
.top-search-sec form input {
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  padding-right: 38px;
  background: #f6f4fc;
}
.top-search-sec button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  height: 48px;
  color: var(--secoundry-color);
}
.top-search-sec form input::placeholder {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #9698a5;
}

/**********not found page ************/

.not-found-bot .add-new {
  margin: 100px 0;
}
.not-found-bot .add-new h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.01em;
}
.not-found-bot .add-new h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--light-gray-color);
}

/*****************add new save ***************/

.btn-cancel {
  background-color: var(--btn-light-color);
  border-color: var(--btn-light-color);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: bold;
  border-radius: 30px;
  padding: 14px 20px;
  text-transform: capitalize;
  color: var(--secoundry-color);
}
.btn-cancel:hover {
  background: transparent;
}

/**********successfull page*******/

.successfull-added {
  height: 100svh;
  height: 100svh;
}
.successfull-added .sucessfull-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #16861a;
  border-radius: 12px;
  padding: 45px 32px;
  max-width: 300px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.successfull-added .sucessfull-box h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--bs-white);
}
.successfull-added .sucessfull-box h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  margin: 0;
  color: var(--bs-white);
  opacity: 0.7;
}

/***********bot details page****************/

.btn-delete {
  background-color: var(--red-color);
  border-color: var(--red-color);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: bold;
  border-radius: 30px;
  padding: 14px 20px;
  text-transform: capitalize;
  color: var(--bs-white);
}
.btn-delete:hover {
  background-color: transparent;
  color: var(--red-color);
}
.bot-details-sec ul li {
  padding: 14px 0;
  border-bottom: 1px solid var(--btn-light-color);
}
.heading-details {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--light-gray-color);
  margin-bottom: 4px;
}
.sub-heading-details {
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.01em;
}

/*****************Profile Management ***************/

.file-upload {
  max-width: 125px;
  margin: 0 auto;
}
.file-upload input {
  opacity: 0;
}
.file-upload label {
  position: absolute;
  bottom: 44px;
  right: 0;
  background: transparent;
  border: none;
  padding: 0;
}
.profile-management-box .profile-img img {
  border: 2px solid #d6d7dc;
}
.profile-management-details .form-group {
  padding: 14px 0;
  border-bottom: 1px solid var(--btn-light-color);
  margin: 0;
}
.profile-management-details .form-group .icons {
  width: 30px;
  margin-right: 10px;
}
.profile-management-details .form-group .details {
  width: 100%;
}
.profile-management-details .form-group .edit-icons {
  width: 20px;
  margin-left: 10px;
}
.profile-management-details .icons i {
  font-size: 20px;
  color: var(--secoundry-color);
}
.profile-management-details .form-group .edit-icons a {
  color: var(--primary-color);
}
.profile-management-details .form-group .details input {
  background: transparent;
  border: none;
  padding: 0;
  font-weight: 600;
}
.profile-management-details .form-group .details input::placeholder {
  color: var(--secoundry-color);
  font-weight: 600;
}

/******************manage use admin*****************/

.tabs-admin ul li button {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--secoundry-color);
  background: transparent;
  padding: 15px 40px;
}
.tabs-admin .nav-tabs .nav-item.show .nav-link,
.tabs-admin .nav-tabs .nav-link.active {
  background-color: transparent !important;
  border: none;
  font-weight: 700;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
.text-unblock {
  color: var(--red-color);
  font-weight: 700;
}
.profile-management-details .form-group .details input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

/***************.User-details ***************/

.User-details .profile-management-details .form-group .details a {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--light-gray-color);
  margin-bottom: 4px;
}
.User-details .profile-management-details .form-group .details a .icons {
  width: auto;
  margin: 0;
}
.User-details .profile-management-details .form-group .details a .icons i {
  color: var(--primary-color);
}
.User-details .profile-management-details .form-group .form-group:last-child {
  border-bottom: none;
}
.primary-color-text {
  color: var(--primary-color);
}
.checkbox-list-item {
  padding: 13px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.02em;
}
.checkbox-list-item .form-check .form-check-input {
  margin: 0;
  width: 20px;
  height: 20px;
}
.checkbox-list-item .form-check {
  min-height: auto;
  margin-bottom: 0;
}
.select-all-sec .checkbox-list-item .form-check {
  margin: 0 14px 0 0;
  padding: 0;
}
.select-all-sec .checkbox-list-item .form-check .form-check-input {
  margin-top: 2px;
}
.select-all-sec .checkbox-list-item .text {
  font-size: 14px;
}

/*******create page********/

.create-profile .box-with-form {
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  background: var(--background-form);
  border-radius: 8px;
  padding: 10px;
}
.create-profile .price-box-left-right .right-group {
  margin-top: 3px;
  width: 60px;
  text-align: center;
}
.create-profile .price-box-left-right .right-group input::placeholder {
  text-align: center;
  font-weight: 800;
  color: var(--secoundry-color);
}
.create-profile .price-box-left-right .left-group {
  width: 100%;
}

/****upload img***********/

.input-single.upload-file {
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-style: dashed;
  padding: 52px 15px;
  position: relative;
  overflow: hidden;
}
.input-single.upload-file input#imgInp {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  border: none;
  opacity: 0;
  z-index: 9;
}
.input-single.upload-file .input-group {
  display: block;
}
.input-single.upload-file .input-group span {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}
.input-single.upload-file .input-group input.form-control {
  opacity: 0;
  display: none;
}
.input-single.upload-file .input-group span svg {
  display: block;
  margin: 0 auto;
}
.input-single.upload-file #img-upload {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--background-form);
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/*********leaderboard page***********/

.leaderboard-section .top-leaderboard {
  background: var(--primary-color);
  padding: 12px;
  text-align: center;
  color: var(--bs-white);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.leaderboard-section .top-leaderboard h3 {
  font-size: 15px;
  font-weight: 900;
  line-height: 18.83px;
  letter-spacing: -0.01em;
  margin: 0;
}
.leaderboard-section .top-leaderboard h3 span {
  display: block;
  color: var(--bs-yellow);
}
.leaderboard-section .leaderboard-body table {
  table-layout: fixed;
  border: 1px solid var(--primary-color);
  background: var(--background-form);
}
.leaderboard-section .leaderboard-body table th:nth-child(3) {
  text-align: center;
  width: 88px;
}
.leaderboard-section .leaderboard-body table th {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  padding: 12px 8px;
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td {
  padding: 12px 8px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16.32px;
  letter-spacing: -0.01em;
}
.leaderboard-section .leaderboard-body table tr:nth-child(2n + 1) td {
  background: var(--bs-light-primary);
}
.leaderboard-section .leaderboard-body table tr td:last-child {
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td .invitations-box {
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  color: var(--primary-color);
}
.leaderboard-section .leaderboard-body table th:nth-child(1) {
  width: 60px;
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td:nth-child(1) {
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td:nth-child(2) {
  text-align: center;
}
.leaderboard-section .leaderboard-body table tr td:nth-child(2) .author-box {
  /* text-align: left;
  max-width: 100px; */
  margin: 0 auto;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 800;
}
/* .leaderboard-section
  .leaderboard-body
  table
  tr
  td:nth-child(2)
  .author-box
  .author-name {
  padding-left: 5px;
} */
.leaderboard-section .leaderboard-body table tr td .invitations-box .icons img {
  width: 14px;
  display: inline-block;
  margin-top: -3px;
  margin-left: 3px;
}
.leaderboard-section .leaderboard-body table {
  border-bottom-left-radius: 8px;
  overflow: hidden;
  border-bottom-right-radius: 8px;
}

/**********select leadboard*********/

.btn.btn-border-with.select {
  background-image: url(./assets/images/select-dropdown.png);
  background-repeat: no-repeat;
  background-position: 97%;
  background-size: 12px;
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #ced4da;
  width: 100%;
  text-align: left;
  padding: 12px 30px 12px 15px;
}
.accordion-box-leader .leaderboard-section {
  margin-top: 8px;
}

/***********drawn-list page*************/

.drawn-list .drawn-box {
  /* border: 1.19px solid #805ed1; */
  border: 1.19px solid var(--primary-color);
  background: var(--background-form);
  border-radius: 8px;
  padding: 11px;
}
.drawn-list .drawn-box .left-box {
  width: calc(100% - 30px);
  padding-right: 10px;
}
.drawn-list .drawn-box .right-box {
  width: 30px;
  text-align: right;
}
.drawn-list .drawn-box .right-box span {
  display: block;
  font-size: 16.7px;
  font-weight: 600;
  line-height: 20.96px;
  letter-spacing: -0.01em;
  margin-bottom: 8px;
}
.drawn-list .drawn-box .right-box span:last-child {
  margin-bottom: 0;
}
.drawn-list .drawn-box .left-box .img-icons {
  width: 50px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;
}
.drawn-list .drawn-box .left-box .img-icons img {
  width: 50px;
  display: block;
  object-fit: cover;
  height: 50px;
}
.drawn-list .drawn-box .left-box .text-box {
  width: calc(100% - 60px);
}
.drawn-list .drawn-box .left-box .text-box p {
  margin: 0;
  font-size: 16.7px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drawn-list .drawn-box .bottom-drawn-box {
  margin-top: 24px;
}
.drawn-list .drawn-box .bottom-drawn-box ul li {
  width: calc(100% - 60px);
  margin-right: 10px;
}
.drawn-list .drawn-box .bottom-drawn-box .border-btn.btn {
  border: 1px solid var(--primary-color);
  width: 100%;
  display: block;
  text-align: center;
  padding: 11px 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: var(--primary-color);
}
.drawn-list .drawn-box .bottom-drawn-box ul li:last-child {
  width: 50px;
  margin-right: 0;
}
.drawn-list .drawn-box .bottom-drawn-box .border-btn.btn svg {
  display: block;
}

/***************send button modal*-************/

.send-modal .modal-header {
  background-color: var(--primary-color);
  padding: 10px 12px;
}
.send-modal .modal-header p {
  margin: 0;
  padding: 0;
  color: var(--bs-white);
  font-size: 15px;
  font-weight: 600;
  line-height: 18.83px;
  letter-spacing: -0.01em;
}
.send-modal .modal-header p span {
  margin-right: 8px;
}
.send-modal .modal-body {
  padding: 10px 12px;
}
.send-modal .modal-body p {
  padding: 5px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  color: var(--bs-black);
}
.send-modal .modal-body span {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  color: var(--light-gray-color);
  display: block;
}
.send-modal .modal-body .btn.btn-primary {
  padding: 13px 6px;
  font-size: 14px;
}
.send-modal .modal-footer {
  padding: 10px 12px;
  border-top: 1px solid var(--primary-color);
}
.send-modal .modal-footer ul li h6 {
  margin: 0;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  letter-spacing: -0.01em;
}
.progressbar-sec {
  padding: 17px 0;
}
.progressbar-sec .progress-bar {
  background: var(--primary-color);
}
.progressbar-sec .progress {
  background: var(--bs-light-primary);
}

/********** Congratulations Animations*********/

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105svh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105svh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105svh, 0) rotateX(10deg) rotateY(250deg);
  }
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
}
.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}
.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}
.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}

/**************congrachulation page***********/

.congratulations-content {
  height: 40svh;
  /* margin-bottom: 30px; */
}
.congratulations-main-sec .js-container.container {
  position: static !important;
  max-width: 100vw;
}
.congratulations-content .congratulations-box {
  background-color: var(--body-bg-color);
  position: absolute;
  /* z-index: 2; */
  border: 1px solid var(--primary-color);
  padding: 15px;
  border-radius: 8px;
}

.congratulations-content .congratulations-box h2 {
  text-transform: uppercase;
  margin: 0 0 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  letter-spacing: -0.01em;
  color: var(--primary-color);
}
.congratulations-content .congratulations-box img {
  margin: 0 7px;
}
.congratulations-content .congratulations-box h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.08px;
  letter-spacing: -0.01em;
  margin: 12px 0 24px;
  color: var(--bs-black);
}
.congratulations-content .congratulations-box .leaderboard-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  letter-spacing: -0.01em;
  padding: 4px 12px;
  background: var(--primary-color);
  color: var(--bs-white);
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 4px;
}
.congratulations-content .congratulations-box p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.01em;
  margin: 8px 0 24px;
}
.congratulations-content .congratulations-box .btn.btn-primary {
  width: 100%;
  font-size: 14px;
}

/* ********************** CountDownTimer ************************** */

.create-draw-time p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}
.create-draw-time {
  margin-top: 25px;
}
.create-draw-time ul li .time-box {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  background: var(--primary-color);
  color: var(--bs-white);
  padding: 10px;
  border-radius: 5px;
  min-width: 60px;
}
.create-draw-time ul li span {
  display: block;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin-top: 4px;
}
.create-draw-time ul li .dots-time {
  position: relative;
}
.create-draw-time ul li .dots-time:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 18px;
  background-color: var(--primary-color);
  left: -3px;
  margin: 0 auto;
}
.create-draw-time ul li .dots-time:after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 29px;
  background-color: var(--primary-color);
  left: -3px;
  margin: 0 auto;
}

/*********************CountDownTimercss ends here ********************/
.box-with-form {
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  background: var(--background-form);
  border-radius: 8px;
  padding: 10px;
}
/*************form-switch default*************/

.form-switch {
  padding: 10px 45px 10px 0;
}
.form-switch .form-check-input {
  margin-left: 0;
  margin-right: -45px;
  border: 1px solid #e8e8e8;
  background-color: #e8e8e8;
  transform: scale(1.4);
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.form-check-input:focus {
  box-shadow: none;
  outline: none;
}

/* ************************Enter screen css ******************************* */
.dark-mode .send-modal .card-footer li svg path {
  stroke: var(--primary-color);
}

.send-modal .card-header {
  background-color: var(--primary-color);
  padding: 10px 12px;
}
.send-modal .card-header p {
  margin: 0;
  padding: 0;
  color: var(--bs-white);
  font-size: 15px;
  font-weight: 600;
  line-height: 18.83px;
  letter-spacing: -0.01em;
}
.send-modal .card-header p span {
  margin-right: 8px;
}
.send-modal .card-body {
  padding: 10px 12px;
}
.send-modal .card-body p {
  padding: 5px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  color: var(--bs-black);
}
.send-modal .card-body span {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  letter-spacing: -0.01em;
  color: var(--light-gray-color);
  display: block;
}
.send-modal .card-body .btn.btn-primary {
  padding: 13px 6px;
  font-size: 14px;
}
.send-modal .card-footer {
  padding: 10px 12px;
  border-top: 1px solid var(--primary-color);
}
.send-modal .card-footer ul li h6 {
  margin: 0;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  letter-spacing: -0.01em;
}
.progressbar-sec {
  padding: 17px 0;
}
.progressbar-sec .progress-bar {
  background: var(--primary-color);
}
.progressbar-sec .progress {
  background: var(--bs-light-primary);
}

/*****************************Enter screen ends here********************/
/*********************Entrants List Css****************************/
.dark-mode .btn.btn-border-with.Entrantce {
  /* color: var(--bs-white); */
}

.btn.btn-border-with.Entrantce {
  background-repeat: no-repeat;
  background-position: 97%;
  background-size: 12px;
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #ced4da;
  width: 100%;
  text-align: left;
  padding: 12px 30px 12px 15px;
}
/*********************Entrants List Css Ends here****************************/

/***********pay modal**************/

.got-to-payment-sec .offcanvas {
  height: 75svh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: var(--background-form);
}
.got-to-payment-sec .offcanvas-header {
  justify-content: center;
  position: relative;
  padding-top: 20px;
}
.got-to-payment-sec .offcanvas-header h5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  letter-spacing: -0.02em;
}
.got-to-payment-sec .offcanvas-header:before {
  content: "";
  background: var(--light-gray-color);
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80px;
  height: 6px;
  border-radius: 14px;
  opacity: 0.5;
}
.got-to-payment-sec .offcanvas .payment-box ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.02em;
  color: var(--bs-black);
  margin: 16px 0;
}
.got-to-payment-sec .offcanvas .payment-box ul li .end-text-payment {
  font-weight: 700;
}
.got-to-payment-sec .offcanvas .payment-box form .form-group {
  margin: 24px 0;
  padding-top: 24px;
  border-top: 1px solid var(--btn-light-color);
}
.got-to-payment-sec .offcanvas .payment-box form .form-group label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  color: var(--light-gray-color);
}
.got-to-payment-sec .offcanvas .payment-box form .form-group h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  letter-spacing: -0.02em;
  margin: 0;
}
/***********pay modal ends here**************/
/* Task details Join Group */
.join-group {
  background: #ffcbcb;
  border-radius: 8px;
  padding: 11px 11px;
}
.join-group p {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
  padding: 0 10px;
}
.join-group p span {
  display: block;
  font-weight: 400;
  font-size: 14px;
}
.join-group .btn.btn-primary {
  padding: 6px 6px;
}
/* settings Css */

.profile-top.portfolio-top-setting .profile-details {
  margin: 0;
}
.confirmbtn-footer {
  width: 100%;
  background-color: var(--primary-color);
  position: fixed;
  bottom: 0;
  height: 50px;
}

::-webkit-file-upload-button {
  height: 50px;
  background-color: var(--primary-color) !important;
  color: var(--secoundry-color) !important ;
}

/* swiperslier  */

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--secoundry-color) !important;
  opacity: 1;
  width: 11px;
  height: 11px;
}

.swiper-pagination-clickable
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--primary-color) !important;
  width: 20px !important;
  border-radius: 4px;
  height: 10px;
}
.btn-primary:disabled {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-primary.disabled {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
