.admin-menu-sec ul li a {
  padding: 13px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  letter-spacing: -0.02em;
}
.admin-menu-sec {
  margin-top: 30px;
}
.pb-100 {
  padding-bottom: 100px;
}
.profile-top {
  margin-top: -70px;
}
.profile-top .profile-img img {
  width: 125px;
  height: 125px;
  border-radius: 100%;
}
.profile-top .profile-details {
  margin: 20px 0 35px;
}
.profile-top .profile-details h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 38px;
  margin: 0;
}
.profile-top .profile-details p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
