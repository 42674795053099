/* CustomPopover.css */
.custom-popover-wrapper {
  position: relative;
  display: inline-block;
}

.custom-popover-content {
  position: absolute;
  bottom: 100%; /* Position above the element */
  left: 50%;
  margin-bottom: 8px;
  padding: 8px;
  background-color: var(--primary-color);
  border: transparent;
  z-index: 1000;
  width: 200px; /* Customize width as needed */
  text-align: center;
  color: white;
}

.custom-popover-content::after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 10px;
  border-width: 8px;
  border-style: solid;
  border-color: var(--primary-color) transparent transparent transparent;
}
